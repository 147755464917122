export * from './createDealNote';
export * from './createDealSubfolder';
export * from './getDealContactId';
export * from './getDealProperties';
export * from './getInstallerNotes';
export * from './getNoteIds';
export * from './getNotes';
export * from './updateDealProperties';
export * from './uploadInstallerFiles';
export * from './batchReadDealProperties';
export * from './batchCreateDealNotes';
export * from './batchUpdateDealDealProperties';
export * from './batchUpdateDealDealProperties';
export * from './getDealByVecId';
export * from './chooseDealByPipeline';
export * from './getDealsByContactId';
export * from './createDeal';
export * from './deleteDealById';
